<template>
  <div class="flex min-h-screen max-h-screen overflow-y-hidden">
    <div v-if="loadFontManager">
      <!-- Add the font manager component. It will preload all the fonts for the whole app -->
      <font-manager />
    </div>

    <div
      class="
        w-80
        bg-truegray-700
        min-h-screen
        flex-shrink-0
        px-6
        pt-6
        overflow-y-auto
      "
    >
      <div class="px-8 mb-6">
        <img src="../assets/images/logo_white.png" />
      </div>
      <div v-if="showingGallery" class="text-white">
        <p class="text-lg mb-2 leading-tight font-bold">
          Willkommen beim Fotobox Vorlagen-Editor!
        </p>
        <p>
          Wähle rechts eine Vorlage aus oder lade eine deiner eigenen Vorlagen.
        </p>

        <div class="mt-2">
          <label
            class="
              inline-flex
              items-center
              transition-all
              duration-150
              text-gray-700
              bg-white
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-gray-700
              px-3
              py-2
              text-sm
              leading-4
              font-medium
              rounded-md
              shadow-sm
              w-full
              justify-center
              cursor-pointer
            "
          >
            <fa icon="upload" class="mr-2" />
            <span>Vorlage importieren (.json)</span>
            <input type="file" accept=".json" ref="jsonField" class="hidden" />
          </label>
        </div>

        <div
          v-if="validOrder"
          class="mt-5 text-white text-xs bg-teal-500 rounded-lg p-4"
        >
          <span><strong>Bestellung:</strong> #{{ orderId }}</span
          ><br />
          <span><strong>E-Mail:</strong> {{ orderEmail }}</span>
        </div>

        <ul class="mt-4 border-t">
          <li>
            <a href="#" class="block -mx-6 px-8 py-1 hover:bg-truegray-600"
               v-on:click.prevent="scrollPageTo('empty-templates')">
              Leere Vorlagen
              <fa icon="chevron-right" class="ml-2 text-sm" />
            </a>
          </li>
          <li>
            <a href="#" class="block -mx-6 px-8 py-1 hover:bg-truegray-600"
               v-on:click.prevent="scrollPageTo('prebuilt-templates-0')">
              1-Foto Vorlagen
              <fa icon="chevron-right" class="ml-2 text-sm" />
            </a>
          </li>
          <li>
            <a href="#" class="block -mx-6 px-8 py-1 hover:bg-truegray-600"
               v-on:click.prevent="scrollPageTo('prebuilt-templates-1')">
              2-Foto Vorlagen
              <fa icon="chevron-right" class="ml-2 text-sm" />
            </a>
          </li>
          <li>
            <a href="#" class="block -mx-6 px-8 py-1 hover:bg-truegray-600"
               v-on:click.prevent="scrollPageTo('prebuilt-templates-2')">
              3-Foto Vorlagen
              <fa icon="chevron-right" class="ml-2 text-sm" />
            </a>
          </li>
          <li>
            <a href="#" class="block -mx-6 px-8 py-1 hover:bg-truegray-600"
               v-on:click.prevent="scrollPageTo('prebuilt-templates-3')">
              4-Foto Vorlagen
              <fa icon="chevron-right" class="ml-2 text-sm" />
            </a>
          </li>
          <li>
            <a href="#" class="block -mx-6 px-8 py-1 hover:bg-truegray-600"
               v-on:click.prevent="scrollPageTo('prebuilt-templates-4')">
              Foto-Streifen Vorlagen
              <fa icon="chevron-right" class="ml-2 text-sm" />
            </a>
          </li>
        </ul>
      </div>

      <div v-else class="divide-y divide-truegray-600 space-y-6">
        <div class="flex flex-col space-y-2">
          <div>
            <label
              class="
                inline-flex
                items-center
                transition-all
                duration-150
                border border-truegray-500
                text-white
                bg-truegray-600
                hover:bg-truegray-700
                px-3
                py-2
                text-sm
                leading-4
                rounded-md
                shadow-sm
                w-full
                justify-center
                cursor-pointer
              "
            >
              <fa icon="image" class="mr-2" />
              <span>Bild hinzufügen</span>
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                ref="imgField"
                class="hidden"
              />
            </label>
          </div>
          <div>
            <base-button
              type="dark"
              size="sm"
              icon="text"
              full-width
              @click="addTextLayer"
              >Text hinzufügen</base-button
            >
          </div>

          <div>
            <base-button
              v-if="validOrder"
              type="primary"
              size="sm"
              icon="upload"
              :loading="gettingOrderStatus"
              full-width
              @click="showUploadTemplateDialog"
              >Vorlage hochladen
            </base-button>
            <base-button
              v-else
              type="primary"
              size="sm"
              icon="save"
              full-width
              @click="downloadTemplateZip"
              >Vorlage exportieren
            </base-button>
          </div>

          <div class="flex space-x-1">
            <base-button
                icon="undo"
                type="dark"
                size="xs"
                full-width
                @click="canvasUndo"
            >Schritt zurück</base-button
            >
            <base-button
                icon="redo"
                type="dark"
                size="xs"
                full-width
                @click="canvasRedo"
            >Wiederherstellen</base-button
            >
          </div>

          <div>
            <base-button @click="refreshPage"
                         size="sm"
                         icon="long-arrow-left"
                         full-width
                         type="dark"> Zurück </base-button>
          </div>

          <!-- <div class="space-y-2">
            <base-button
              type="dark"
              size="sm"
              full-width
              @click="addGuidesLandscape"
              >Hilfslinien (Normal)
            </base-button>
            <base-button
              type="dark"
              size="sm"
              full-width
              @click="addGuidesStripe"
              >Hilfslinien (Streifen)
            </base-button>
          </div> -->

          <div
            v-if="validOrder"
            class="
              text-white text-xs
              border border-truegray-500
              rounded-lg
              py-2
              px-3
            "
          >
            <span><strong>Bestellung:</strong> #{{ orderId }}</span
            ><br />
            <span><strong>E-Mail:</strong> {{ orderEmail }}</span>
          </div>

          <base-dialog v-model:show="showingUploadDialog">
            <DialogTitle
              as="h3"
              class="text-lg font-medium leading-6 text-truegray-900"
            >
              Vorlage zu Fotobox hochladen
            </DialogTitle>

            <div class="mt-2 text-sm text-truegray-600">
              <p>
                Nach dem Klick auf »Hochladen« wird deine Vorlage zu Fotobox
                hochgeladen und für folgende Bestellung gespeichert:
              </p>
              <p class="mt-1.5">
                <span><strong>Bestellung:</strong> #{{ orderId }}</span
                ><br />
                <span><strong>E-Mail:</strong> {{ orderEmail }}</span>
              </p>
              <div
                v-if="overwritingExistingTemplate"
                class="
                  mt-2
                  bg-orange-300
                  rounded-lg
                  py-2.5
                  px-4
                  text-orange-900 text-xs
                "
              >
                <strong>Achtung!</strong><br />
                Du überschreibst damit deine vorher hochgeladene Vorlage.
              </div>
            </div>
            <template v-slot:footer="slotProps">
              <base-button
                :loading="uploadingTemplate"
                :disable-on-loading="true"
                type="primary"
                @click="uploadTemplate"
              >
                Hochladen
              </base-button>
              <base-button @click="slotProps.close"> Abbrechen </base-button>
            </template>
          </base-dialog>

          <base-dialog v-model:show="showingUploadSuccessDialog">
            <DialogTitle
              as="h3"
              class="text-lg font-medium leading-6 text-green-600"
            >
              Upload erfolgreich!
            </DialogTitle>

            <div class="mt-2 text-sm text-truegray-600">
              <p>
                Deine Vorlage wurde erfolgreich zu Fotobox hochgeladen.
              </p>
            </div>
            <template v-slot:footer="slotProps">
              <base-button @click="slotProps.close"> Schließen </base-button>
            </template>
          </base-dialog>
        </div>
        <!-- <div class="pt-3" v-if="layers.length > 0">
          <span class="text-xs uppercase font-medium text-white">Ebenen</span>
          <div
            class="w-full bg-white max-h-60 px-3 py-2 rounded-md text-xs mt-0.5"
          >
            <ul class="space-y-1">
              <li
                @click="selectObjectByName(layer.name)"
                class="block w-full py-0.5 px-2 rounded-sm cursor-pointer"
                :class="{
                  'bg-gray-700 text-white': selectedObjectName === layer.name,
                }"
                v-for="layer in layers"
                :key="layer.name"
              >
                {{ layer.type }} ({{ layer.name }})
              </li>
            </ul>
          </div>
        </div> -->

        <div v-if="selectedObject" class="pt-3 space-y-2">
          <item-dimensions-control />

          <div class="flex space-x-1 pt-4">
            <item-alignment-control />
          </div>

          <div
            class="flex space-x-1"
            v-if="selectedObjectAttributes.type === 'image'"
          >
            <base-button
              icon="intersection"
              type="dark"
              size="xs"
              full-width
              @click="setMaskingOn"
              >Als Hintergrund verwenden</base-button
            >
            <base-button
              icon="union"
              type="dark"
              size="xs"
              full-width
              @click="setMaskingOff"
              >Grafik in den Vordergrund</base-button
            >
          </div>

          <div class="flex space-x-1">
            <base-button
              type="dark"
              icon="bring-front"
              size="xs"
              full-width
              @click="bringForward"
              >Nach vorne bringen</base-button
            >
          </div>

          <div class="flex space-x-1">
            <base-button
              icon="lock"
              type="dark"
              size="xs"
              full-width
              @click="lockObject"
              >Element fixieren</base-button
            >
            <base-button
              icon="unlock-alt"
              type="dark"
              size="xs"
              full-width
              @click="unlockObject"
              >Element lösen</base-button
            >
          </div>

          <div class="flex space-x-1">
            <base-button
              type="dark"
              size="xs"
              full-width
              icon="clone"
              @click="duplicateObject"
              >Duplizieren</base-button
            >
            <base-button
              icon="trash-alt"
              type="dark"
              size="xs"
              full-width
              @click="removeObject"
              >Löschen</base-button
            >
          </div>

          <div v-if="selectedObjectAttributes.type === 'i-text'">
            <div class="w-full mt-6">
              <label
                class="block text-xs uppercase font-medium text-white mb-0.5"
                >Schriftart</label
              >
              <font-picker-tool v-model="selectedObjectFont" />
            </div>

            <div class="w-full mt-6">
              <div class="color-picker-wrapper">
                <ColorPicker
                    theme="dark"
                    :color="selectedObjectAttributes.color"
                    @changeColor="setSelectedObjectColor"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="pt-3">
          <h2 class="text-white text-sm mb-2">Hintergrundfarbe</h2>
          <vue-gpickr v-model="canvasGradient" />
        </div>

      </div>
    </div>

    <div class="w-full min-h-screen overflow-x-hidden">
      <div v-if="showingGallery" class="w-full p-6">
        <!--START::Empty templates-->
        <div v-for="(template, index) in emptyTemplates" id="empty-templates" class="mb-6"
             :key="index">
          <h2 class="font-bold text-2xl mb-2 text-truegray-700">
            {{template.heading}}
          </h2>
          <div class="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
            <div
                v-for="template in template.templates"
                :key="template.name"
                class="
              bg-gray-200
              shadow-md
              rounded-md
              p-4
              xl:p-5
              2xl:p-6
              w-full
              space-y-2
              flex flex-col
            ">
              <h2 class="font-bold">{{ template.name }}</h2>
              <img :src="template.preview" />

              <base-button
                  class="mt-1"
                  type="primary"
                  full-width
                  @click="loadTemplate(template.url)"
              >Auswählen</base-button
              >
            </div>
          </div>
        </div>

        <!--END::Empty templates-->

        <!--START::Prebuilt templates-->
        <div v-for="(template, index) in prebuiltTemplates" :id="'prebuilt-templates-' + index" class="mb-6"
             :key="index">
          <h2 class="font-bold text-2xl mb-2 text-truegray-700">
            {{template.heading}}
          </h2>
          <div class="grid grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
            <div
                v-for="template in template.templates"
                :key="template.name"
                class="
              bg-gray-200
              shadow-md
              rounded-md
              p-4
              xl:p-5
              2xl:p-6
              w-full
              space-y-2
              flex flex-col
            ">
              <h2 class="font-bold">{{ template.name }}</h2>
              <img :src="template.preview" />

              <base-button
                  class="mt-1"
                  type="primary"
                  full-width
                  @click="loadTemplate(template.url)"
              >Auswählen</base-button
              >
            </div>
          </div>
        </div>
        <!--END::Prebuilt templates-->
      </div>
      <div
        v-else
        id="fabric-canvas-wrapper"
        ref="canWrapper"
        class="w-full min-h-screen"
      >
        <canvas ref="canvas" width="800" height="600"></canvas>
      </div>
    </div>

    <base-dialog v-if="!showingGallery" v-model:show="showingGuideWarning">
      <DialogTitle
        as="h3"
        class="text-lg font-medium leading-6 text-orange-600"
      >
        Achte auf die Hilfslinien!
      </DialogTitle>

      <div class="mt-2 text-sm text-truegray-600">
        <p>
          Achte darauf keine Text- oder Bildelemente außerhalb der türkisen
          Hilfslinien zu platzieren, da diese sonst möglicherweise beim Druck
          abgeschnitten werden.
        </p>
      </div>
      <template v-slot:footer="slotProps">
        <base-button @click="slotProps.close"> Verstanden </base-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, computed } from "vue";
import { useRoute } from "vue-router";
import decode from "jwt-decode";
import { DialogTitle } from "@headlessui/vue";
import { ColorPicker } from "vue-color-kit";
// import "vue-color-kit/dist/vue-color-kit.css";
import useCanvas from "@/composables/canvas";
import FontManager from "@/components/FontManager";
import FontPickerTool from "@/components/tools/FontPicker";
import BaseButton from "@/components/BaseButton";
import BaseDialog from "@/components/BaseDialog";
import ItemDimensionsControl from "@/components/ItemDimensionsControl";
import ItemAlignmentControl from "@/components/ItemAlignmentControl";
import "fabric-history";
import { VueGpickr, LinearGradient } from 'vue-gpickr';

export default {
  components: {
    BaseButton,
    FontManager,
    FontPickerTool,
    BaseDialog,
    DialogTitle,
    ColorPicker,
    ItemDimensionsControl,
    ItemAlignmentControl,
    VueGpickr,
  },
  watch: {
    // whenever color changes, this function will run
    canvasGradient(newColors, oldColors) {
      if(this.watchingCanvasGradient){
        this.setCanvasGradientFromPicker(newColors);
      }
    }
  },
  methods: {
    refreshPage() {
      if(confirm("Bist du dir sicher? Nicht gespeicherte Änderungen gehen möglicherweise verloren.")){
        // Reload the current page
        this.disposeCanvas();
        this.showingGallery = true;
        this.canvas = null;
      }
    }
  },
  setup() {
    const route = useRoute();
    const jwt = ref(null);
    const orderId = ref(null);
    const orderEmail = ref(null);
    const canvas = ref(null);
    const canWrapper = ref(null);
    const jsonField = ref(null);
    const imgField = ref(null);
    const showingGallery = ref(true);
    const loadFontManager = ref(false);
    const watchingCanvasGradient = ref(false);
    const canvasGradient = ref(null);
    const showingUploadDialog = ref(false);
    const showingGuideWarning = ref(true);
    const showingUploadSuccessDialog = ref(false);
    const gettingOrderStatus = ref(false);
    const uploadingTemplate = ref(false);
    const overwritingExistingTemplate = ref(false);
    const {
      initCanvas,
      disposeCanvas,
      loadJSONTemplate,
      addTextLayer,
      resizeCanvas,
      handleImageSelect,
      getTemplateAsZip,
      selectedObject,
      selectedObjectAttributes,
      selectedObjectName,
      setMaskingOn,
      setMaskingOff,
      lockObject,
      unlockObject,
      duplicateObject,
      removeObject,
      bringForward,
      selectedObjectFont,
      setSelectedObjectColor,
      downloadTemplateZip,
      layers,
      selectObjectByName,
      canvasUndo,
      canvasRedo,
      getCanvasGradientForPicker,
      setCanvasGradientFromPicker,
      // addGuidesLandscape,
      // addGuidesStripe,

    } = useCanvas();
    
    const emptyTemplates = [
      {
        heading: "Leere Vorlagen mit einem Foto:",
        templates: [
          {
            name: "1Er Gerade",
            url: "empty/1er-gerade",
            preview: require("../assets/images/templates/empty/1er-gerade.jpg"),
          },
          {
            name: "1Er Schraeg Polaroid",
            url: "empty/1er-schraeg-polaroid",
            preview: require("../assets/images/templates/empty/1er-schraeg-polaroid.jpg"),
          },
          {
            name: "1Er Fullscreen",
            url: "empty/1er-vollbild",
            preview: require("../assets/images/templates/empty/1er-vollbild.jpg"),
          }
        ]
      },
      {
        heading: "Leere Vorlage \"Layout-Kombination (LK1)\":",
        templates: [
          {
            name: "1Er Square",
            url: "empty/1er-square",
            preview: require("../assets/images/templates/empty/1er-square.jpg"),
          },
          {
            name: "3Er Square",
            url: "empty/3er-square-streifen",
            preview: require("../assets/images/templates/empty/3er-square-streifen.jpg"),
          },
        ]
      },
      {
        heading: "Leere Vorlagen mit zwei Fotos:",
        templates: [
          {
            name: "2Er Gerade",
            url: "empty/2er-gerade",
            preview: require("../assets/images/templates/empty/2er-gerade.jpg"),
          },
          {
            name: "2Er Sheraeg",
            url: "empty/2er-schraeg",
            preview: require("../assets/images/templates/empty/2er-schraeg.jpg"),
          },
        ]
      },
      {
        heading: "Leere Vorlagen mit drei Fotos:",
        templates: [
          {
            name: "3Er Gerade",
            url: "empty/3er-gerade",
            preview: require("../assets/images/templates/empty/3er-gerade.jpg"),
          },
          {
            name: "3Er Schraeg",
            url: "empty/3er-schraeg",
            preview: require("../assets/images/templates/empty/3er-schraeg.jpg"),
          },
        ]
      },
      {
        heading: "Leere Vorlagen mit vier Fotos:",
        templates: [
          {
            name: "4Er Centered",
            url: "empty/4er-centered",
            preview: require("../assets/images/templates/empty/4er-centered.jpg"),
          },
          {
            name: "4Er Schraeg",
            url: "empty/4er-schraeg",
            preview: require("../assets/images/templates/empty/4er-schraeg.jpg"),
          },
          {
            name: "4Er Twolines Gerade",
            url: "empty/4er-twolines-gerade",
            preview: require("../assets/images/templates/empty/4er-twolines-gerade.jpg"),
          },
        ]
      },
      {
        heading: "Leere Vorlagen mit Streifen:",
        templates: [
          {
            name: "3Er Streifen",
            url: "empty/3er-streifen",
            preview: require("../assets/images/templates/empty/3er-streifen.jpg"),
          },
          {
            name: "4Er Streifen",
            url: "empty/4er-streifen",
            preview: require("../assets/images/templates/empty/4er-streifen.jpg"),
          },
        ]
      },
    ];

    const prebuiltTemplates = [
      {
        heading: "Vorgefertigte Vorlagen mit einem Foto:",
        templates: [
          {
            name: "1.1",
            url: "prebuilt/1.1",
            preview: require("../assets/images/templates/prebuilt/1.1.jpg"),
          },
          {
            name: "1.2",
            url: "prebuilt/1.2",
            preview: require("../assets/images/templates/prebuilt/1.2.jpg"),
          },
          {
            name: "1.3",
            url: "prebuilt/1.3",
            preview: require("../assets/images/templates/prebuilt/1.3.jpg"),
          },
          {
            name: "1.4",
            url: "prebuilt/1.4",
            preview: require("../assets/images/templates/prebuilt/1.4.jpg"),
          },
          {
            name: "1.5",
            url: "prebuilt/1.5",
            preview: require("../assets/images/templates/prebuilt/1.5.jpg"),
          },
          {
            name: "1.6",
            url: "prebuilt/1.6",
            preview: require("../assets/images/templates/prebuilt/1.6.jpg"),
          },
          {
            name: "1.7",
            url: "prebuilt/1.7",
            preview: require("../assets/images/templates/prebuilt/1.7.jpg"),
          },
          {
            name: "1.8",
            url: "prebuilt/1.8",
            preview: require("../assets/images/templates/prebuilt/1.8.jpg"),
          },
          {
            name: "1.9",
            url: "prebuilt/1.9",
            preview: require("../assets/images/templates/prebuilt/1.9.jpg"),
          },
          {
            name: "1.10",
            url: "prebuilt/1.10",
            preview: require("../assets/images/templates/prebuilt/1.10.jpg"),
          },
          {
            name: "1.11",
            url: "prebuilt/1.11",
            preview: require("../assets/images/templates/prebuilt/1.11.jpg"),
          },
          {
            name: "1.12",
            url: "prebuilt/1.12",
            preview: require("../assets/images/templates/prebuilt/1.12.jpg"),
          },
          {
            name: "1.13",
            url: "prebuilt/1.13",
            preview: require("../assets/images/templates/prebuilt/1.13.jpg"),
          },
          {
            name: "1.14",
            url: "prebuilt/1.14",
            preview: require("../assets/images/templates/prebuilt/1.14.jpg"),
          },
          {
            name: "1.15",
            url: "prebuilt/1.15",
            preview: require("../assets/images/templates/prebuilt/1.15.jpg"),
          },
          {
            name: "1.16",
            url: "prebuilt/1.16",
            preview: require("../assets/images/templates/prebuilt/1.16.jpg"),
          },
          {
            name: "1.17",
            url: "prebuilt/1.17",
            preview: require("../assets/images/templates/prebuilt/1.17.jpg"),
          },
          {
            name: "1.18",
            url: "prebuilt/1.18",
            preview: require("../assets/images/templates/prebuilt/1.18.jpg"),
          },
          {
            name: "1.19",
            url: "prebuilt/1.19",
            preview: require("../assets/images/templates/prebuilt/1.19.jpg"),
          },
          {
            name: "1.20",
            url: "prebuilt/1.20",
            preview: require("../assets/images/templates/prebuilt/1.20.jpg"),
          },
          {
            name: "1.21",
            url: "prebuilt/1.21",
            preview: require("../assets/images/templates/prebuilt/1.21.jpg"),
          },
          {
            name: "1.22",
            url: "prebuilt/1.22",
            preview: require("../assets/images/templates/prebuilt/1.22.jpg"),
          },
          {
            name: "1.23",
            url: "prebuilt/1.23",
            preview: require("../assets/images/templates/prebuilt/1.23.jpg"),
          },
          {
            name: "1.24",
            url: "prebuilt/1.24",
            preview: require("../assets/images/templates/prebuilt/1.24.jpg"),
          },
          {
            name: "1.25",
            url: "prebuilt/1.25",
            preview: require("../assets/images/templates/prebuilt/1.25.jpg"),
          },
          {
            name: "1.26",
            url: "prebuilt/1.26",
            preview: require("../assets/images/templates/prebuilt/1.26.jpg"),
          },
          {
            name: "1.27",
            url: "prebuilt/1.27",
            preview: require("../assets/images/templates/prebuilt/1.27.jpg"),
          },
          {
            name: "1.28",
            url: "prebuilt/1.28",
            preview: require("../assets/images/templates/prebuilt/1.28.jpg"),
          },
          {
            name: "1.29",
            url: "prebuilt/1.29",
            preview: require("../assets/images/templates/prebuilt/1.29.jpg"),
          },
          {
            name: "1.30",
            url: "prebuilt/1.30",
            preview: require("../assets/images/templates/prebuilt/1.30.jpg"),
          },
          {
            name: "1.31",
            url: "prebuilt/1.31",
            preview: require("../assets/images/templates/prebuilt/1.31.jpg"),
          },
        ]
      },
      {
        heading: "Vorgefertigte Vorlagen mit 2 Fotos:",
        templates: [
          {
            name: "2.1",
            url: "prebuilt/2.1",
            preview: require("../assets/images/templates/prebuilt/2.1.jpg"),
          },
          {
            name: "2.2",
            url: "prebuilt/2.2",
            preview: require("../assets/images/templates/prebuilt/2.2.jpg"),
          },
          {
            name: "2.3",
            url: "prebuilt/2.3",
            preview: require("../assets/images/templates/prebuilt/2.3.jpg"),
          },
          {
            name: "2.4",
            url: "prebuilt/2.4",
            preview: require("../assets/images/templates/prebuilt/2.4.jpg"),
          },
          {
            name: "2.5",
            url: "prebuilt/2.5",
            preview: require("../assets/images/templates/prebuilt/2.5.jpg"),
          },
          {
            name: "2.6",
            url: "prebuilt/2.6",
            preview: require("../assets/images/templates/prebuilt/2.6.jpg"),
          },
          {
            name: "2.7",
            url: "prebuilt/2.7",
            preview: require("../assets/images/templates/prebuilt/2.7.jpg"),
          },
          {
            name: "2.8",
            url: "prebuilt/2.8",
            preview: require("../assets/images/templates/prebuilt/2.8.jpg"),
          },
        ]
      },
      {
        heading: "Vorgefertigte Vorlagen mit 3 Fotos",
        templates: [
          {
            name: "3.1",
            url: "prebuilt/3.1",
            preview: require("../assets/images/templates/prebuilt/3.1.jpg"),
          },
          {
            name: "3.2",
            url: "prebuilt/3.2",
            preview: require("../assets/images/templates/prebuilt/3.2.jpg"),
          },
          {
            name: "3.3",
            url: "prebuilt/3.3",
            preview: require("../assets/images/templates/prebuilt/3.3.jpg"),
          },
          {
            name: "3.4",
            url: "prebuilt/3.4",
            preview: require("../assets/images/templates/prebuilt/3.4.jpg"),
          },
          {
            name: "3.5",
            url: "prebuilt/3.5",
            preview: require("../assets/images/templates/prebuilt/3.5.jpg"),
          },
          {
            name: "3.6",
            url: "prebuilt/3.6",
            preview: require("../assets/images/templates/prebuilt/3.6.jpg"),
          },
          {
            name: "3.7",
            url: "prebuilt/3.7",
            preview: require("../assets/images/templates/prebuilt/3.7.jpg"),
          },
          {
            name: "3.8",
            url: "prebuilt/3.8",
            preview: require("../assets/images/templates/prebuilt/3.8.jpg"),
          },
          {
            name: "3.9",
            url: "prebuilt/3.9",
            preview: require("../assets/images/templates/prebuilt/3.9.jpg"),
          },
          {
            name: "3.10",
            url: "prebuilt/3.10",
            preview: require("../assets/images/templates/prebuilt/3.10.jpg"),
          },
          {
            name: "3.11",
            url: "prebuilt/3.11",
            preview: require("../assets/images/templates/prebuilt/3.11.jpg"),
          },
          {
            name: "3.12",
            url: "prebuilt/3.12",
            preview: require("../assets/images/templates/prebuilt/3.12.jpg"),
          },
          {
            name: "3.13",
            url: "prebuilt/3.13",
            preview: require("../assets/images/templates/prebuilt/3.13.jpg"),
          },
          {
            name: "3.14",
            url: "prebuilt/3.14",
            preview: require("../assets/images/templates/prebuilt/3.14.jpg"),
          },
          {
            name: "3.15",
            url: "prebuilt/3.15",
            preview: require("../assets/images/templates/prebuilt/3.15.jpg"),
          },
          {
            name: "3.16",
            url: "prebuilt/3.16",
            preview: require("../assets/images/templates/prebuilt/3.16.jpg"),
          },
          {
            name: "3.17",
            url: "prebuilt/3.17",
            preview: require("../assets/images/templates/prebuilt/3.17.jpg"),
          },
          {
            name: "3.18",
            url: "prebuilt/3.18",
            preview: require("../assets/images/templates/prebuilt/3.18.jpg"),
          },
          {
            name: "3.19",
            url: "prebuilt/3.19",
            preview: require("../assets/images/templates/prebuilt/3.19.jpg"),
          },
          {
            name: "3.20",
            url: "prebuilt/3.20",
            preview: require("../assets/images/templates/prebuilt/3.20.jpg"),
          },
          {
            name: "3.21",
            url: "prebuilt/3.21",
            preview: require("../assets/images/templates/prebuilt/3.21.jpg"),
          },
        ]
      },
      {
        heading: "Vorgefertigte Vorlagen mit 4 Fotos:",
        templates: [
          {
            name: "4.1",
            url: "prebuilt/4.1",
            preview: require("../assets/images/templates/prebuilt/4.1.jpg"),
          },
          {
            name: "4.2",
            url: "prebuilt/4.2",
            preview: require("../assets/images/templates/prebuilt/4.2.jpg"),
          },
          {
            name: "4.3",
            url: "prebuilt/4.3",
            preview: require("../assets/images/templates/prebuilt/4.3.jpg"),
          },
          {
            name: "4.5",
            url: "prebuilt/4.5",
            preview: require("../assets/images/templates/prebuilt/4.5.jpg"),
          },
          {
            name: "4.6",
            url: "prebuilt/4.6",
            preview: require("../assets/images/templates/prebuilt/4.6.jpg"),
          },
          {
            name: "4.7",
            url: "prebuilt/4.7",
            preview: require("../assets/images/templates/prebuilt/4.7.jpg"),
          },
          {
            name: "4.8",
            url: "prebuilt/4.8",
            preview: require("../assets/images/templates/prebuilt/4.8.jpg"),
          },
          {
            name: "4.9",
            url: "prebuilt/4.9",
            preview: require("../assets/images/templates/prebuilt/4.9.jpg"),
          },
          {
            name: "4.10",
            url: "prebuilt/4.10",
            preview: require("../assets/images/templates/prebuilt/4.10.jpg"),
          },
          {
            name: "4.11",
            url: "prebuilt/4.11",
            preview: require("../assets/images/templates/prebuilt/4.11.jpg"),
          },
          {
            name: "4.12",
            url: "prebuilt/4.12",
            preview: require("../assets/images/templates/prebuilt/4.12.jpg"),
          },
          {
            name: "4.13",
            url: "prebuilt/4.13",
            preview: require("../assets/images/templates/prebuilt/4.13.jpg"),
          },
        ]
      },
      {
        heading: "Vorgefertigte Streifen-Vorlagen mit einem 3 oder 4 Fotos:",
        templates: [
          {
            name: "S1",
            url: "prebuilt/S1",
            preview: require("../assets/images/templates/prebuilt/S1.jpg"),
          },
          {
            name: "S2",
            url: "prebuilt/S2",
            preview: require("../assets/images/templates/prebuilt/S2.jpg"),
          },
          {
            name: "S3",
            url: "prebuilt/S3",
            preview: require("../assets/images/templates/prebuilt/S3.jpg"),
          },
          {
            name: "S4",
            url: "prebuilt/S4",
            preview: require("../assets/images/templates/prebuilt/S4.jpg"),
          },
          {
            name: "S5",
            url: "prebuilt/S5",
            preview: require("../assets/images/templates/prebuilt/S5.jpg"),
          },
          {
            name: "S6",
            url: "prebuilt/S6",
            preview: require("../assets/images/templates/prebuilt/S6.jpg"),
          },
          {
            name: "S7",
            url: "prebuilt/S7",
            preview: require("../assets/images/templates/prebuilt/S7.jpg"),
          },
          {
            name: "S8",
            url: "prebuilt/S8",
            preview: require("../assets/images/templates/prebuilt/S8.jpg"),
          },
          {
            name: "S9",
            url: "prebuilt/S9",
            preview: require("../assets/images/templates/prebuilt/S9.jpg"),
          },
          {
            name: "S10",
            url: "prebuilt/S10",
            preview: require("../assets/images/templates/prebuilt/S10.jpg"),
          },
          {
            name: "S11",
            url: "prebuilt/S11",
            preview: require("../assets/images/templates/prebuilt/S11.jpg"),
          },
          {
            name: "S12",
            url: "prebuilt/S12",
            preview: require("../assets/images/templates/prebuilt/S12.jpg"),
          },
          {
            name: "S13",
            url: "prebuilt/S13",
            preview: require("../assets/images/templates/prebuilt/S13.jpg"),
          },
          {
            name: "S14",
            url: "prebuilt/S14",
            preview: require("../assets/images/templates/prebuilt/S14.jpg"),
          },
          {
            name: "S15",
            url: "prebuilt/S15",
            preview: require("../assets/images/templates/prebuilt/S15.jpg"),
          },
          {
            name: "S16",
            url: "prebuilt/S16",
            preview: require("../assets/images/templates/prebuilt/S16.jpg"),
          },
          {
            name: "S17",
            url: "prebuilt/S17",
            preview: require("../assets/images/templates/prebuilt/S17.jpg"),
          },
        ]
      }
    ];

    onMounted(() => {

      canvasGradient.value = new LinearGradient({
        angle: 0,
        stops: [
          ['#ffffff', 0],
          ['#ffffff', 1],
        ]
      });

      // Read JWT from query param
      if (route.query.sid) {
        const token = decode(route.query.sid);

        if (!token.exp) {
          alert("Zugriffscode ungültig. Bitte neuen holen.");
        }

        const date = new Date(0);
        date.setUTCSeconds(token.exp);

        if (date < new Date()) {
          alert("Zugriffscode abgelaufen. Bitte neuen holen.");
        } else {
          console.log("Zugriffscode gültig!");
          jwt.value = route.query.sid;
          orderId.value = token.order;
          orderEmail.value = token.sub;
        }
      }

      window.onbeforeunload = function () {
        return "Bist du dir sicher? Nicht gespeicherte Änderungen gehen möglicherweise verloren.";
      };

      // Import Json
      jsonField.value.addEventListener("change", function (e) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = async function (f) {
          var data = f.target.result;

          showingGallery.value = false;
          await nextTick();

          initCanvas(canvas, canWrapper);
          await nextTick();

          imgField.value.addEventListener("change", handleImageSelect);

          loadJSONTemplate(data, function () {
            nextTick(function(){
              let templateColors = getCanvasGradientForPicker();
              canvasGradient.value = new LinearGradient(templateColors);
              watchingCanvasGradient.value = true;
            });
          }, function (error) {
            // Handle error if loadFromJSON fails
          });
        };
        reader.readAsText(file);
      });

      window.addEventListener("resize", resizeCanvas);
    });

    window.addEventListener("history-changed", function(){
      watchingCanvasGradient.value = false;

      let templateColors = getCanvasGradientForPicker();
      canvasGradient.value = new LinearGradient(templateColors);

      watchingCanvasGradient.value = true;
    });

    const validOrder = computed(
      () => jwt.value && orderId.value && orderEmail.value
    );

    function nextTick(callback) {
      setTimeout(callback);
    }

    async function loadTemplate(name) {
      showingGallery.value = false;

      await nextTick();

      initCanvas(canvas, canWrapper);

      //let file = require("../assets/templates/" + name + ".json");

      let file = await import("../assets/templates/" + name + ".json");

      await nextTick();

      imgField.value.addEventListener("change", handleImageSelect);

      loadJSONTemplate(file, function () {
        nextTick(function(){
          let templateColors = getCanvasGradientForPicker();
          canvasGradient.value = new LinearGradient(templateColors);
          watchingCanvasGradient.value = true;
        });
      }, function (error) {
        // Handle error if loadFromJSON fails
      });
    }

    async function showUploadTemplateDialog() {
      gettingOrderStatus.value = true;

      const res = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/editor/get-status?jwt=${jwt.value}`
      );
      if (!res.ok) {
        alert(
          "Zugriffscode abgelaufen oder ungültig. Bitte Editor über Kundenbereich aufrufen."
        );
        gettingOrderStatus.value = false;
        return;
      }

      const data = await res.json();
      gettingOrderStatus.value = false;

      if (data.upload_possible == false) {
        alert("Upload für diese Bestellung ist nicht mehr möglich");
        return;
      }

      if (data.template_exists == true) {
        overwritingExistingTemplate.value = true;
      } else {
        overwritingExistingTemplate.value = false;
      }

      showingUploadDialog.value = true;
    }

    async function uploadTemplate() {
      uploadingTemplate.value = true;
      const formData = new FormData();
      const zip = await getTemplateAsZip();
      const blob = new Blob([zip.buffer]);
      formData.append("jwt", jwt.value);
      formData.append("file", blob);
      formData.append("_method", "PATCH");

      const res = await fetch(
        `${process.env.VUE_APP_API_BASE_URL}/editor/upload-template`,
        {
          method: "POST",
          body: formData,
        }
      );
      if (!res.ok) {
        uploadingTemplate.value = false;
        alert("Fehler beim Hochladen");
        return;
      }

      uploadingTemplate.value = false;
      showingUploadDialog.value = false;
      showingUploadSuccessDialog.value = true;
    }

    function scrollPageTo(sectionId){
      let element = document.getElementById(sectionId);

      element.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }

    window.addEventListener('DOMContentLoaded', function(){
      loadFontManager.value = true;
    });

    return {
      disposeCanvas,
      canWrapper,
      canvas,
      showingGallery,
      loadFontManager,
      watchingCanvasGradient,
      canvasGradient,
      loadTemplate,
      scrollPageTo,
      validOrder,
      orderId,
      orderEmail,
      jsonField,
      imgField,
      showingUploadDialog,
      showingUploadSuccessDialog,
      showUploadTemplateDialog,
      gettingOrderStatus,
      addTextLayer,
      uploadTemplate,
      uploadingTemplate,
      overwritingExistingTemplate,
      selectedObject,
      selectedObjectAttributes,
      bringForward,
      setMaskingOn,
      setMaskingOff,
      lockObject,
      unlockObject,
      duplicateObject,
      removeObject,
      selectedObjectFont,
      setSelectedObjectColor,
      downloadTemplateZip,
      // addGuidesLandscape,
      // addGuidesStripe,
      emptyTemplates,
      prebuiltTemplates,
      layers,
      selectedObjectName,
      selectObjectByName,
      canvasUndo,
      canvasRedo,
      getCanvasGradientForPicker,
      setCanvasGradientFromPicker,
      showingGuideWarning,
    };
  },
};
</script>