// This is a plugin to configure fonts for the whole App

// This is our google fonts API key
var googleFontsApiKey = "AIzaSyAXgnA5n9TFZNmF_CwnNG7mTnrCzbmOyC0";

// Define a default font
var defaultFont = "Comforter";

// Lets define our app fonts
// Todo: Some fonts are disabled. It seems we cannot load them or the names are incorrect
var fontList = [
  "Roboto",
  "Montagu Slab",
  "Estonia",
  "Praise",
  "Oswald",
  "Ubuntu",
  "Playfair Display",
  "Yuji Mai",
  "Lora",
  "Quicksand",
  "Inconsolata",
  "Petemoss",
  "Dosis",
  "Lobster",
  "Dancing Script",
  "Comforter Brush",
  "Architects Daughter",
  "Pacifico",
  "Yuji Boku",
  "Indie Flower",
  "Cormorant Garamond",
  "Passions Conflict",
  "Satisfy",
  "Comforter",
  "Shalimar",
  "Sassy Frass",
  "Cinzel",
  "Hurricane",
  "Staatliches",
  "Parisienne",
  "Sacramento",
  "Luckiest Guy",
  "M PLUS 2",
  "M PLUS Code Latin",
  "Nanum Gothic",
  "Noto Serif",
  "Shadows Into Light",
  // "Crimson Text", // => ist keine Google Font. Es gibt eine, die ähnlich heißt => "Crimson Pro"?
  // "Muskat", // => gibt es nicht als Google Font. Außerdem wird die evtl. "Muscat" geschrieben?
  // "Open Sans Condensed", // => Die gibt es nicht als regular. Der Picker kann aber momentan nur regular :-(
];

// Sort the fonts
fontList = fontList.sort();

// Provide a Vue plugin
export default {
  install(app) {
    // Provide some data to all app components
    app.provide("googleFontsApiKey", googleFontsApiKey);
    app.provide("fontList", fontList);
    app.provide("defaultFont", defaultFont);
  },
};
