<template>
  <base-button
    type="dark"
    icon="arrow-to-left"
    size="xs"
    full-width
    @click="alignLeftH"
    >Links</base-button
  >
  <base-button
    icon="border-center-v"
    type="dark"
    size="xs"
    full-width
    @click="alignMiddleH"
    >Mitte</base-button
  >
  <base-button
    icon="arrow-to-right"
    type="dark"
    size="xs"
    full-width
    @click="alignRightH"
    >Rechts</base-button
  >
</template>

<script>
import useCanvas from "@/composables/canvas";
import BaseButton from "@/components/BaseButton";

export default {
  components: {
    BaseButton,
  },
  setup() {
    const { alignLeftH, alignMiddleH, alignRightH } = useCanvas();

    return {
      alignLeftH,
      alignMiddleH,
      alignRightH,
    };
  },
};
</script>
