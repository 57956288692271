<template>
  <TransitionRoot appear :show="show" as="template">
    <DialogJs as="div" @close="closeModal">
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="min-h-screen px-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-black opacity-70" />
          </TransitionChild>

          <span class="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <div
              class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
            >
              <slot></slot>

              <div class="mt-4 flex space-x-2 items-center">
                <slot name="footer" :close="closeModal"></slot>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </DialogJs>
  </TransitionRoot>
</template>

<script>
import {
  TransitionRoot,
  TransitionChild,
  Dialog as DialogJs,
  DialogOverlay,
} from "@headlessui/vue";

export default {
  name: "BaseDialog",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:show"],
  components: {
    TransitionRoot,
    TransitionChild,
    DialogJs,
    DialogOverlay,
  },

  setup(props, { emit }) {
    function closeModal() {
      emit("update:show", false);
    }

    function openModal() {
      emit("update:show", true);
    }

    return {
      closeModal,
      openModal,
    };
  },
};
</script>
