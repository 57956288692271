<template>
  <div class="flex space-x-2">
    <div>
      <label class="block text-xs uppercase font-medium text-white mb-0.5"
        >X-Position</label
      >
      <div>
        <input
          :value="selectedObjectLeft"
          @blur="selectedObjectLeft = $event.target.value"
          @keyup.enter="selectedObjectLeft = $event.target.value"
          type="text"
          class="
            py-1
            shadow-sm
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            sm:text-sm
            border-transparent
            rounded-md
          "
        />
      </div>
    </div>
    <div>
      <label class="block text-xs uppercase font-medium text-white mb-0.5"
        >Y-Position</label
      >
      <div>
        <input
          :value="selectedObjectTop"
          @blur="selectedObjectTop = $event.target.value"
          @keyup.enter="selectedObjectTop = $event.target.value"
          type="text"
          class="
            py-1
            shadow-sm
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            sm:text-sm
            border-transparent
            rounded-md
          "
        />
      </div>
    </div>
  </div>
  <div class="flex space-x-2">
    <div>
      <label class="block text-xs uppercase font-medium text-white mb-0.5"
        >Breite</label
      >
      <div>
        <input
          :value="selectedObjectScaleX"
          @blur="selectedObjectScaleX = $event.target.value"
          @keyup.enter="selectedObjectScaleX = $event.target.value"
          type="text"
          class="
            py-1
            shadow-sm
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            sm:text-sm
            border-transparent
            rounded-md
          "
        />
      </div>
    </div>
    <div>
      <label class="block text-xs uppercase font-medium text-white mb-0.5"
        >Höhe</label
      >
      <div>
        <input
          :value="selectedObjectScaleY"
          @blur="selectedObjectScaleY = $event.target.value"
          @keyup.enter="selectedObjectScaleY = $event.target.value"
          type="text"
          class="
            py-1
            shadow-sm
            focus:ring-indigo-500 focus:border-indigo-500
            block
            w-full
            sm:text-sm
            border-transparent
            rounded-md
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import useCanvas from "@/composables/canvas";

export default {
  components: {},
  setup() {
    const {
      selectedObjectLeft,
      selectedObjectTop,
      selectedObjectScaleX,
      selectedObjectScaleY,
    } = useCanvas();

    return {
      selectedObjectLeft,
      selectedObjectTop,
      selectedObjectScaleX,
      selectedObjectScaleY,
    };
  },
};
</script>
