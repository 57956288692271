import { computed, ref, reactive } from "vue";
import { fabric } from "fabric";
import { nanoid } from "nanoid";
import * as fflate from "fflate";
import * as dayjs from "dayjs";
const download = require("downloadjs");

let canvas = null;
let canvasInitialized = false;
const state = reactive({
  selectedObjectAttributes: {
    type: null,
    left: 0,
    top: 0,
    width: 0,
    height: 0,
    fontFamily: null,
    color: "#000000",
  },
  templateDimensionsRect: null,
  templateMasks: null,
  templateGuides: null,
  templatePadding: 30,
  canvasWrapperRef: null,
  exportCounter: 0,
});

const layers = ref([]);
const selectedObjectName = ref(null);

export default function useCanvas() {
  const selectedObject = computed(function () {
    if (selectedObjectName.value) {
      return canvas.getItemByName(selectedObjectName.value);
    } else {
      return null;
    }
  });

  const selectedObjectLeft = computed({
    get() {
      return state.selectedObjectAttributes.left;
    },
    set(newValue) {
      setSelectedObjectLeft(parseInt(newValue));
    },
  });

  const selectedObjectTop = computed({
    get() {
      return state.selectedObjectAttributes.top;
    },
    set(value) {
      setSelectedObjectTop(parseInt(value));
    },
  });

  const selectedObjectScaleX = computed({
    get() {
      return state.selectedObjectAttributes.width;
    },
    set(value) {
      setSelectedObjectScaleX(parseInt(value));
    },
  });

  const selectedObjectScaleY = computed({
    get() {
      return state.selectedObjectAttributes.height;
    },
    set(value) {
      setSelectedObjectScaleY(parseInt(value));
    },
  });

  const selectedObjectFont = computed({
    get() {
      return state.selectedObjectAttributes.fontFamily;
    },
    set(value) {
      setSelectedObjectFont(value);
    },
  });

  function refreshLayers() {
    const tempLayers = canvas.getObjects();
    const filteredLayers = tempLayers.filter(
      (layer) => layer.name != "templateDimensionsRect"
    );

    //--- Keep template guides always on top, Must have "locked", "selectable" and "event" properties set properly
    filteredLayers.forEach(function(layer){
      if(layer.name === 'templateGuides'){
        //---- Make template guides unselectable for undo/redo
        layer.selectable = false;
        layer.evented = false;
        layer.locked = true;

        layer.bringToFront();
      }

      //--- Set controls medium left, medium bottom, medium right, medium left to false i.e(allow only 4 controls boxes)
      layer.setControlsVisibility({
        ml: false,
        mb: false,
        mr: false,
        mt: false,
      });
    });

    let mappedLayers = filteredLayers.map(function (layer) {
      return {
        name: layer.name,
        type: layer.type,
      };
    });

    layers.value = mappedLayers.reverse();
  }

  function updateselectedObject(sourceObj) {
    state.selectedObjectAttributes.type = sourceObj.type;
    state.selectedObjectAttributes.left = Math.round(sourceObj.left);
    state.selectedObjectAttributes.top = Math.round(sourceObj.top);
    state.selectedObjectAttributes.width = Math.round(
      sourceObj.width * sourceObj.scaleX
    );
    state.selectedObjectAttributes.height = Math.round(
      sourceObj.height * sourceObj.scaleY
    );
    if (sourceObj.type === "i-text") {
      state.selectedObjectAttributes.fontFamily = sourceObj.fontFamily;
      state.selectedObjectAttributes.color = sourceObj.fill;
    }
  }

  function extendObjectExport(obj) {
    obj.toObject = (function (toObject) {
      return function () {
        return fabric.util.object.extend(toObject.call(this), {
          name: this.name,
          locked: this.locked,
        });
      };
    })(obj.toObject);
  }

  function zoomToTemplate() {
    canvas.setZoom(1);
    canvas.absolutePan({
      x: -state.templatePadding,
      y: -state.templatePadding,
    });

    if (
      state.templateDimensionsRect.width >= state.templateDimensionsRect.height
    ) {
      canvas.setZoom(
        state.canvasWrapperRef.clientWidth /
          (state.templateDimensionsRect.width + (state.templatePadding + 120) * 2)
      );
    } else {
      canvas.setZoom(
        state.canvasWrapperRef.clientHeight /
          (state.templateDimensionsRect.height + (state.templatePadding + 120) * 2)
      );
    }
  }

  function initCanvas(canvasRef, canvasWrapperRef) {
    if (canvasRef == undefined || canvasWrapperRef == undefined) {
      throw Error(
        "You must pass a wrapper for the canvas and the canvas wrapper div"
      );
    }

    if (canvasInitialized == true) {
      throw Error("Canvas was already initialized");
    }

    console.log("Init Canvas");

    fabric.Object.prototype.transparentCorners = false;

    fabric.Canvas.prototype.getItemByName = function (name) {
      var object = null,
        objects = this.getObjects();

      for (var i = 0, len = this.size(); i < len; i++) {
        if (objects[i].name && objects[i].name === name) {
          object = objects[i];
          break;
        }
      }

      return object;
    };

    canvas = new fabric.Canvas(canvasRef.value, {
      backgroundColor: null,
      preserveObjectStacking: true,
    });

    canvas.extraProps = ['selectable', 'editable', 'name', 'evented'];

    state.canvasWrapperRef = canvasWrapperRef;

    canvas.setDimensions({
      width: state.canvasWrapperRef.clientWidth,
      height: state.canvasWrapperRef.clientHeight,
    });

    canvas.on("selection:created", function (event) {
      if (event.selected.length == 1 && event.selected[0].name) {
        selectedObjectName.value = event.selected[0].name;
        updateselectedObject(event.selected[0]);
        refreshLayers();
        console.log(`Selected ${event.selected[0].name}`);
      }
    });

    canvas.on("selection:updated", function (event) {
      if (event.selected.length == 1 && event.selected[0].name) {
        selectedObjectName.value = event.selected[0].name;
        updateselectedObject(event.selected[0]);
        refreshLayers();
        console.log(`Selected ${event.selected[0].name}`);
      }
    });

    canvas.on("selection:cleared", function () {
      console.log("Selection cleared");
      selectedObjectName.value = null;
    });

    canvas.on("object:modified", function (event) {
      console.log(`Modified ${event.target.name}`);
      updateselectedObject(event.target);
    });

    canvas.on("object:added", function (event) {
      console.log(`Added ${event.target.name}`);
      refreshLayers();
    });

    canvas.on("object:removed", function (event) {
      console.log(`Removed ${event.target.name}`);
      refreshLayers();
    });

    canvasInitialized = true;

    canvas.renderAll();

    document.addEventListener('keyup', ({ keyCode, ctrlKey } = event) => {
      // Check Ctrl key is pressed.
      if (!ctrlKey) {
        return
      }

      // Check pressed button is Z - Ctrl+Z.
      if (keyCode === 90) {
        canvas.undo()
      }

      // Check pressed button is Y - Ctrl+Y.
      if (keyCode === 89) {
        canvas.redo()
      }
    });
  }

  function loadJSONTemplate(json, successCallback, errorCallback) {
    if (!canvasInitialized) {
      throw Error("Canvas must be initialized before loading a template");
    }

    canvas.loadFromJSON(json, function () {
      state.templateDimensionsRect = canvas.getItemByName(
          "templateDimensionsRect"
      );

      extendObjectExport(state.templateDimensionsRect);
      state.templateDimensionsRect.set("selectable", false);
      state.templateDimensionsRect.set("hoverCursor", "default");
      state.templateMasks = state.templateDimensionsRect.clipPath;

      // Set up guides
      state.templateGuides = canvas.getItemByName("templateGuides");
      if (state.templateGuides) {
        extendObjectExport(state.templateGuides);
        state.templateGuides.set("selectable", false);
        state.templateGuides.set("hoverCursor", "default");
      }

      // export name for all custom objects
      for (let [index, val] of canvas.getObjects().entries()) {
        if (val.type === "image" || val.type === "i-text") {
          extendObjectExport(canvas.getObjects()[index]);
          canvas.getObjects()[index].setControlsVisibility({
            ml: false,
            mb: false,
            mr: false,
            mt: false,
          });
        }

        // if (val.type === "i-text") {
        //   // Bring Text to Front (TEMP)
        //   canvas.getObjects()[index].bringToFront();
        //   console.log("Bring text layers to front");
        // }

        if (canvas.getObjects()[index].locked == true) {
          canvas.getObjects()[index].set("lockMovementX", true);
          canvas.getObjects()[index].set("lockMovementY", true);
          canvas.getObjects()[index].set("lockScalingX", true);
          canvas.getObjects()[index].set("lockScalingY", true);
          canvas.getObjects()[index].set("lockRotation", true);
        }
      }

      //--- Clear canvas history, so we detect only changes after canvas initialized
      refreshLayers();
      zoomToTemplate();

      canvas.clearHistory();

      successCallback();
    }, function (error) {
      errorCallback(error);
    });
  }

  function resizeCanvas() {
    if (canvasInitialized) {
      const outerCanvasContainer = document.getElementById(
        "fabric-canvas-wrapper"
      );

      const containerWidth = outerCanvasContainer.clientWidth;
      const containerHeight = outerCanvasContainer.clientHeight;
      // const scale = containerWidth / this.canvas.getWidth();
      // const zoom = this.canvas.getZoom() * scale;

      canvas.setDimensions({
        width: containerWidth,
        height: containerHeight,
      });
      //this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
      console.log(`New Canvas width: ${containerWidth}px`);
      console.log(`New Canvas height: ${containerHeight}px`);

      zoomToTemplate();
    }
  }

  function addGuidesLandscape() {
    let line_1 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 30,
      width: 1,
      height: 1248,
    });
    let line_2 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 1817,
      width: 1,
      height: 1248,
    });
    let line_3 = new fabric.Rect({
      fill: "cyan",
      top: 30,
      left: 0,
      width: 1847,
      height: 1,
    });
    let line_4 = new fabric.Rect({
      fill: "cyan",
      top: 1218,
      left: 0,
      width: 1847,
      height: 1,
    });
    let guides = new fabric.Group([line_1, line_2, line_3, line_4], {
      name: "templateGuides",
      hasControls: false,
      absolutePositioned: true,
    });
    extendObjectExport(guides);
    guides.set("selectable", false);
    guides.set("hoverCursor", "default");
    canvas.add(guides);
  }

  function addGuidesStripe() {
    let line_1 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 45,
      width: 1,
      height: 1847,
    });
    let line_2 = new fabric.Rect({
      fill: "cyan",
      top: 0,
      left: 1203,
      width: 1,
      height: 1847,
    });
    let line_3 = new fabric.Rect({
      fill: "cyan",
      top: 45,
      left: 0,
      width: 1248,
      height: 1,
    });
    let line_4 = new fabric.Rect({
      fill: "cyan",
      top: 1772,
      left: 0,
      width: 1248,
      height: 1,
    });
    let guides = new fabric.Group([line_1, line_2, line_3, line_4], {
      name: "templateGuides",
      hasControls: false,
      absolutePositioned: true,
    });
    extendObjectExport(guides);
    guides.set("selectable", false);
    guides.set("hoverCursor", "default");
    canvas.add(guides);
  }

  function addTextLayer() {
    var text = new fabric.IText("Dein Text", {
      // fontFamily: this.defaultFont,
      fontFamily: "Comforter", // TODO: Fix
      left: 20,
      top: 20,
    });
    text.set("name", nanoid());
    text.setControlsVisibility({
      ml: false,
      mb: false,
      mr: false,
      mt: false,
    });
    extendObjectExport(text);
    canvas.add(text);
  }

  function handleImageSelect(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (f) {
      var data = f.target.result;
      fabric.Image.fromURL(data, function (img) {
        img.setControlsVisibility({
          ml: false,
          mb: false,
          mr: false,
          mt: false,
        });
        img.set("name", nanoid());
        extendObjectExport(img);
        canvas.add(img);
        canvas.setActiveObject(img);
        canvas.renderAll();
      });
    };
    reader.readAsDataURL(file);

    e.target.value = null;
  }

  function readFileAsync(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  async function getTemplateAsZip() {
    let guides = canvas.getItemByName("templateGuides");
    if (guides) {
      guides.set("visible", false);
    }

    let templateDimensionsRect = canvas.getItemByName(
        "templateDimensionsRect"
    );

    //--- Increase width and height by 2 pixels to fix the 1px transparent gap in exported png
    templateDimensionsRect.set('width', templateDimensionsRect.width + 2);
    templateDimensionsRect.set('height', templateDimensionsRect.height + 2);

    canvas.set("backgroundColor", null);
    canvas.renderAll.bind(canvas)();
    canvas.setZoom(1);
    canvas.absolutePan({
      x: 0,
      y: 0,
    });

    //--- Set left position and top position 1 to remove 1px gap in exported png, Also adjust the width and height -2 pixels
    var dataURL = canvas.toDataURL({
      format: "png",
      left: 0,
      top: 0,
      width: templateDimensionsRect.width - 2,
      height: templateDimensionsRect.height - 2,
    });
    const blob = await (await fetch(dataURL)).blob();

    let contentBuffer = await readFileAsync(blob);

    if (guides) {
      guides.set("visible", true);
    }

    //--- Decrease width and height by 2 pixels which was default before increasing it, So json values will be intact.
    templateDimensionsRect.set('width', templateDimensionsRect.width - 2);
    templateDimensionsRect.set('height', templateDimensionsRect.height - 2);

    const zipped = fflate.zipSync(
      {
        "vorlage.json": fflate.strToU8(JSON.stringify(canvas.toDatalessJSON(canvas.extraProps))),
        "grafik.png": [new Uint8Array(contentBuffer), { level: 0 }],
      },
      {
        // These options are the defaults for all files, but file-specific
        // options take precedence.
        //level: 1,
        // Obfuscate mtime by default
        //mtime: 0
      }
    );

    zoomToTemplate();
    canvas.set("backgroundColor", null);
    canvas.renderAll();

    return zipped;
  }

  async function downloadTemplateZip() {
    let zip = await getTemplateAsZip();
    const timestamp = dayjs().format("YYYYMMDD-HH-mm");
    state.exportCounter++;
    download(
      zip,
      `fotobox_vorlage_${timestamp}_${state.exportCounter}.zip`,
      "application/zip"
    );
  }

  function setSelectedObjectLeft(left) {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("left", left);
    state.selectedObjectAttributes.left = left;
    canvas.renderAll();
  }

  function setSelectedObjectTop(top) {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("top", top);
    state.selectedObjectAttributes.top = top;
    canvas.renderAll();
  }

  function setSelectedObjectScaleX(width) {
    let element = canvas.getItemByName(selectedObjectName.value);
    const scale = width / element.width;
    element.set({
      scaleX: scale,
      scaleY: scale,
    });
    state.selectedObjectAttributes.width = width;
    state.selectedObjectAttributes.height = Math.round(scale * element.height);
    canvas.renderAll();
  }

  function setSelectedObjectScaleY(height) {
    let element = canvas.getItemByName(selectedObjectName.value);
    const scale = height / element.height;
    element.set({
      scaleX: scale,
      scaleY: scale,
    });
    state.selectedObjectAttributes.height = height;
    state.selectedObjectAttributes.width = Math.round(scale * element.width);
    canvas.renderAll();
  }

  function setSelectedObjectFont(value) {
    let element = canvas.getItemByName(selectedObjectName.value);
    state.selectedObjectAttributes.fontFamily = value;
    element.set("fontFamily", value);
    canvas.renderAll();
  }

  function setSelectedObjectColor(value) {
    let element = canvas.getItemByName(selectedObjectName.value);
    state.selectedObjectAttributes.color = value.hex;
    element.set(
      "fill",
      `rgba(${value.rgba.r},${value.rgba.g},${value.rgba.b},${value.rgba.a})`
    );
    canvas.renderAll();
  }

  function lockObject() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("lockMovementX", true);
    element.set("lockMovementY", true);
    element.set("lockScalingX", true);
    element.set("lockScalingY", true);
    element.set("lockRotation", true);
    element.set("locked", true);
  }

  function unlockObject() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.set("lockMovementX", false);
    element.set("lockMovementY", false);
    element.set("lockScalingX", false);
    element.set("lockScalingY", false);
    element.set("lockRotation", false);
    element.set("locked", false);
  }

  function duplicateObject() {
    canvas.getActiveObject().clone(function (clone) {
      clone.set("left", canvas.getActiveObject().left + 20);
      clone.set("top", canvas.getActiveObject().top + 20);
      clone.set("name", nanoid());
      clone.setControlsVisibility({
        ml: false,
        mb: false,
        mr: false,
        mt: false,
      });
      extendObjectExport(clone);
      canvas.add(clone);
    });
  }

  function setMaskingOn() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.clipPath = state.templateMasks;

    let tempLayers = canvas.getObjects();

    //--- Bring selectable objects to front, When an image is pushed to background other elements were not selectable
    tempLayers.filter((layer) => layer.name !== "templateDimensionsRect" && layer !== element)
        .forEach(function(layer){
          if(layer.selectable){
            console.log(layer.type, layer.selectable);
            layer.bringToFront();
          }
        });

    canvas.renderAll();
  }

  function setMaskingOff() {
    let element = canvas.getItemByName(selectedObjectName.value);
    element.clipPath = null;
    canvas.renderAll();
  }

  function bringForward() {
    let element = canvas.getItemByName(selectedObjectName.value);
    // Bring to front instead of only one step forward
    // element.bringForward();
    element.bringToFront();
    refreshLayers();
  }

  function alignLeftH() {
    setSelectedObjectLeft(0);
  }

  function alignMiddleH() {
    let element = canvas.getItemByName(selectedObjectName.value);

    setSelectedObjectLeft(
      Math.round(
        state.templateDimensionsRect.width * 0.5 -
          element.width * element.scaleX * 0.5
      )
    );
  }

  function alignRightH() {
    let element = canvas.getItemByName(selectedObjectName.value);

    setSelectedObjectLeft(
      Math.round(
        state.templateDimensionsRect.width - element.width * element.scaleX
      )
    );
  }

  function removeObject() {
    let element = canvas.getItemByName(selectedObjectName.value);
    canvas.remove(element);
    canvas.renderAll();
  }

  function selectObjectByName(name) {
    let element = canvas.getItemByName(name);
    canvas.setActiveObject(element);
    canvas.renderAll();
  }

  function canvasUndo(){
    canvas.undo();

    window.dispatchEvent(new CustomEvent('history-changed'));
  }

  function canvasRedo(){
    canvas.redo();

    window.dispatchEvent(new CustomEvent('history-changed'));
  }

  function getCanvasGradientForPicker(){
    let colors = {
      angle: 0,
      stops: [
        ['#ffffff', 0],
        ['#ffffff', 1],
      ]};

    //--- if template dimensions rectangle is null then return default white colors
    if( !state.templateDimensionsRect ){
      return colors;
    }

    let templateDimensionsRect = canvas.getItemByName(
        "templateDimensionsRect"
    );

    let templateDimensionsRectFill = templateDimensionsRect.fill;

    //--- if template dimensions rectangle fill is not an object then return default white colors
    if(typeof templateDimensionsRectFill !== 'object'){
      return colors;
    }

    //--- if template dimensions rectangle fill is null (transparent) return default white colors with transparency
    if(!templateDimensionsRectFill){
      colors.stops[0][0] = '#ffffff00'
      colors.stops[1][0] = '#ffffff00'
      return colors;
    }

    let coords = templateDimensionsRectFill.coords;

    let angle = getAngleFromCoordinates(coords.x1, coords.y1, coords.x2, coords.y2);

    let stops = [];

    templateDimensionsRectFill.colorStops.forEach(function(colorStop){
      stops.push([colorStop.color, colorStop.offset]);
    });

    colors.angle = angle;
    colors.stops = stops;

    return colors;
  }

  function setCanvasGradientFromPicker(colors){
    let stops = [];

    colors.stops.forEach(function(colorStop){
      stops.push({offset : colorStop[1], color: colorStop[0]})
    });

    let coords = getCoordinatesFromAngle(colors.angle, state.templateDimensionsRect);

    let gradient = new fabric.Gradient({
      type: 'linear',
      gradientUnits: 'pixels', // or 'percentage'
      coords: coords,
      colorStops:stops
    });

    let templateDimensionsRect = canvas.getItemByName(
        "templateDimensionsRect"
    );

    templateDimensionsRect.set('fill', gradient);

    //canvas.fire("object:modified", {target: state.templateDimensionsRect});

    canvas.renderAll();
  }

  function getCoordinatesFromAngle(angle, object){
    let coords = {};

    //var angleRadians = (colors.angle - 90) * Math.PI / 180;

    var angleRadians = fabric.util.degreesToRadians(angle - 90);

    // Calculate the endpoint coordinates
    coords.x1 = object.width / 2 - (object.width / 2) * Math.cos(angleRadians);
    coords.y1 = object.height / 2 - (object.height / 2) * Math.sin(angleRadians);
    coords.x2 = object.width / 2 + (object.width / 2) * Math.cos(angleRadians);
    coords.y2 = object.height / 2 + (object.height / 2) * Math.sin(angleRadians);

    return coords;
  }

  function getAngleFromCoordinates(x1, y1, x2, y2){
    let deltaX = x2 - x1;
    let deltaY = y2 - y1;

    let radianAngle = Math.atan2(deltaY, deltaX);
    let  degreeAngle = Math.round(((radianAngle * 180) / Math.PI) + 90);

    return degreeAngle;
  }

  function disposeCanvas(){
    canvas.dispose();
    canvasInitialized = false;
  }

  return {
    addGuidesLandscape,
    addGuidesStripe,
    initCanvas,
    disposeCanvas,
    loadJSONTemplate,
    addTextLayer,
    resizeCanvas,
    handleImageSelect,
    getTemplateAsZip,
    downloadTemplateZip,
    selectedObject,
    selectedObjectLeft,
    selectedObjectTop,
    selectedObjectScaleX,
    selectedObjectScaleY,
    selectedObjectFont,
    setSelectedObjectColor,
    selectedObjectName,
    lockObject,
    unlockObject,
    duplicateObject,
    setMaskingOn,
    setMaskingOff,
    bringForward,
    alignLeftH,
    alignMiddleH,
    alignRightH,
    removeObject,
    selectObjectByName,
    canvasUndo,
    canvasRedo,
    getCanvasGradientForPicker,
    setCanvasGradientFromPicker,
    getAngleFromCoordinates,
    layers,
    selectedObjectAttributes: state.selectedObjectAttributes,
  };
}
