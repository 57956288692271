<template>
  <div class="font-manager">
    <font-picker-tool :init-fonts="true" />

    <!--
      This was another try to catch all the fonts.
      Display all fonts and force the browser to download the font files.
      But unfortunately this will not work.
      Later (on select) the fontpicker will download different font files again.
      This behaviour comes from the fontPicker itself
     -->
    <!-- <div
      v-for="font in fonts"
      :style="'font-family:' + font"
      :key="font"
      >{{ font }}</div> -->
  </div>
</template>

<script>
// This is a component to wrap some preloading code for the google fonts and the font picker
// We will just load an invisible fontpicker with the attribute "init-fonts"
// This picker will then use its internal FontsManager to preload all the fonts
import FontPickerTool from "@/components/tools/FontPicker";

export default {
  components: { FontPickerTool },
  inject: ["fontList"],
  data: function () {
    // console.log(this.fontList);
    return {
      fonts: this.fontList,
    };
  },
};
</script>

<style scoped lang="postcss">
/*
  Do not set this to display:hidden;
  We have to find another way to make this invisible.
  Otherwise the FontPicker will not preload the fonts.
*/
.font-manager {
  overflow: hidden;
  width: 0;
}
</style>
