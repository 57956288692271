<template>
  <div>
    <div :id="'font-picker-' + id"></div>
  </div>
</template>

<script>
// This is a wrapper for the FontPicker:
// https://github.com/samuelmeuli/font-picker

import FontPicker from "font-picker";

export default {
  props: ["modelValue", "initFonts"],
  emits: ["update:modelValue"],
  inject: ["fontList", "googleFontsApiKey"],
  data() {
    // console.log('fontpicker fonts', this.fontList);

    return {
      // Generate a random ID:
      // https://stackoverflow.com/questions/3231459/create-unique-id-with-javascript
      // This is required by the picker
      id: String(Math.random().toString(16).slice(2)),
      pickerInstance: null,
      fonts: this.fontList,
    };
  },
  mounted() {
    var component = this;

    component.$nextTick(function () {
      // Create a new font picker instance
      component.pickerInstance = new FontPicker(
        this.googleFontsApiKey,
        this.modelValue,
        {
          pickerId: component.id,
          families: this.fonts,
          variants: ["regular"],
        }
      );

      // Listen to font picker changes
      component.pickerInstance.setOnChange(function (font) {
        // Emit this font back to the App
        component.$emit("update:modelValue", font.family);
      });

      // Use this option to force the FontPicker to download and precache all the required fonts.
      // This is required because of our Canvas. The fonts have to be ready before the canvas renders the first time.
      // Unfortunately we have to use a timeout in here because "fontManager" is not part of the official FontPicker API.
      // So we have to wait until all fonts are available in the FontPicker.
      if (component.initFonts) {
        setTimeout(function () {
          component.fonts.forEach(function (font) {
            component.pickerInstance.fontManager.setActiveFont(font);
          });
        }, 2000);
      }
    });
  },
  watch: {
    modelValue: function (newVal) {
      this.pickerInstance.setActiveFont(newVal);
    },
  },
};
</script>

<style lang="postcss">
/*
  Note: We will use !important in some cases here because the FontPicker will generate inline styles :-(
*/

/* Make the picker full width */
div[id^="font-picker"] {
  width: 100% !important;
}

/* Apply form input styles */
div[id^="font-picker"] .dropdown-button {
  background-color: #fff !important;
  @apply bg-white py-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-transparent rounded-md;
}

div[id^="font-picker"] .dropdown-button:hover,
div[id^="font-picker"] .dropdown-button:focus {
  @apply bg-white py-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-transparent rounded-md;
}

/*
  Remove rounded bottom borders if expanded
  Use a transition delay to match the expansion time
*/
div[id^="font-picker"] .dropdown-button {
  transition-delay: 0.3s;
  transition-property: border-radius;
}
div[id^="font-picker"].expanded .dropdown-button {
  @apply rounded-b-none;
  transition-delay: 0s;
}

/*
  Disable user selection of the picker contents
  Sometimes while picking fonts a highlight selection of fonts can happen accidentally
  That looks stupid then
*/
div[id^="font-picker"] {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
